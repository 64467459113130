.toggle {
  display: inline-block;
  min-width: 87px;
}

.label {
  display: inline-block;
  vertical-align: top;
  padding: 12px 5px 0 10px;
  font-size: 14px;
  color: #9e9e9e;
}

.icon {
  vertical-align: top;
  display: inline-block;
}
